import Resource from "./resource.js";
export class Organizations extends Resource {
    /**
     * Organizations
     */
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}/organizations",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
    find = this.makeRequest({
        method: "GET",
        path: "/",
    });
    findOne = this.makeRequest({
        method: "GET",
        path: "/{id}",
        urlParamKeys: ["id"],
    });
    create = this.makeRequest({
        method: "POST",
        path: "/",
        returnResourceIdInLocationHeader: { field: "id" },
    });
    delById = this.makeRequest({
        method: "DELETE",
        path: "/{id}",
        urlParamKeys: ["id"],
    });
    updateById = this.makeUpdateRequest({
        method: "PUT",
        path: "/{id}",
        urlParamKeys: ["id"],
    });
    listMembers = this.makeRequest({
        method: "GET",
        path: "/{orgId}/members",
        urlParamKeys: ["orgId"],
    });
    addMember = this.makeRequest({
        method: "POST",
        path: "/{orgId}/members",
        urlParamKeys: ["orgId"],
        payloadKey: "userId",
    });
    delMember = this.makeRequest({
        method: "DELETE",
        path: "/{orgId}/members/{userId}",
        urlParamKeys: ["orgId", "userId"],
    });
    memberOrganizations = this.makeRequest({
        method: "GET",
        path: "/members/{userId}/organizations",
        urlParamKeys: ["userId"],
    });
    invite = this.makeUpdateRequest({
        method: "POST",
        path: "/{orgId}/members/invite-user",
        urlParamKeys: ["orgId"],
    });
    inviteExistingUser = this.makeUpdateRequest({
        method: "POST",
        path: "/{orgId}/members/invite-existing-user",
        urlParamKeys: ["orgId"],
    });
    listIdentityProviders = this.makeRequest({
        method: "GET",
        path: "/{orgId}/identity-providers",
        urlParamKeys: ["orgId"],
    });
    linkIdp = this.makeRequest({
        method: "POST",
        path: "/{orgId}/identity-providers",
        urlParamKeys: ["orgId"],
        payloadKey: "alias",
    });
    unLinkIdp = this.makeRequest({
        method: "DELETE",
        path: "/{orgId}/identity-providers/{alias}",
        urlParamKeys: ["orgId", "alias"],
    });
}
